import DocumentUpdateDialog from "./DocumentUpdateDialog.vue"

import { type DatasheetBinding } from './../add_datasheet_row/types'

export function updateDialog (bindingData: DatasheetBinding, callback: (documentIds: null | Array<string>) => void) {
  const app = Vue.createApp(DocumentUpdateDialog, bindingData)

  const template = document.createElement('DIV')
  const instance = app.mount(template)

  instance.$.vnode.props = {
    onCallback: (...args: any[]) => {
      callback(args[0])

      template.remove()
    }
  }

  document.body.appendChild(template)
}
